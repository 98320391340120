<script>
  import RepgenList from "../components/repgen/RepgenList.vue";

  export default {
    components: { RepgenList }
  }
</script>

<template>

<div>
    <router-link :to="{name: 'RepGen' }" class="px-3 py-2 text-white bg-blue-400 rounded text-md">
      <span class="text-md">&#10094;&#10094;</span>&nbsp;&nbsp; All Active Repgens
    </router-link>
  </div>

  <RepgenList :archived="true" />
</template>
